import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  required,
  CardActions,
  TextField,
  EditButton,
  SimpleForm,
  Create,
  ReferenceField,
  AutocompleteInput,
  DisabledInput,
  ReferenceInput,
  Edit,
  SelectInput,
  SimpleShowLayout,
  LongTextInput,
  DateField,
  Filter,
  Datagrid,
  List,
  FunctionField,
  BooleanInput,
  BooleanField,
  ShowController,
  ShowView,
  FormDataConsumer,
  REDUX_FORM_NAME,
} from 'react-admin';
import Moment from 'moment';
import Chip from '@material-ui/core/Chip';
import { change, untouch } from 'redux-form';
import DateTwoTimeInput from './DateTwoTimeInput';
import AbortMeetingButton from './meetings/AbortMeetingButton';
import { MeetingStatus, MeetingTypes, getName } from './constants';

export const MeetingCreate = (props) => {
  const startDate = props.location && props.location.state && props.location.state.startDate
    ? props.location.state.startDate
    : new Date();
  const finishDate = props.location && props.location.state && props.location.state.finishDate
    ? props.location.state.finishDate
    : new Date();
  const meetingListId = props.location.state
    && props.location.state.record
    && props.location.state.record.meetingListId
    ? props.location.state.record.meetingListId
    : null;
  return (
    <Create {...props} title="Création rendez-vous">
      <SimpleForm
        redirect={
          meetingListId ? `/MeetingList/${meetingListId}/show` : '/CalendarDay'
        }
      >
        {meetingListId && (
          <DisabledInput
            label="Id de la liste de consultation"
            source="meetingListId"
            style={{
              display: 'none',
            }}
          />
        )}
        <DateTwoTimeInput
          onlyTime={meetingListId}
          startDate={startDate}
          finishDate={finishDate}
        />
        {!meetingListId && (
          <Fragment>
            <ReferenceInput
              label="Opérateur"
              reference="User"
              source="operatorId"
              validate={required()}
              filter={{ type: 'Operator' }}
              allowEmpty
            >
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <BooleanInput label="Rdv à l'extérieur ?" source="isOutside" />
            <BooleanInput label="Notifier l'opérateur ?" source="notifyOperator" />
          </Fragment>
        )}
        <SelectInput
          label="Type"
          source="type"
          choices={MeetingTypes}
          validate={required()}
        />
        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => (
            <ReferenceInput
              label="Patient"
              reference="Patient"
              source="patientId"
              validate={required()}
              perPage={10}
              helperText="Nom Prénom Date de naissance (08-06-2001)"
              onChange={() => {
                dispatch(change(REDUX_FORM_NAME, 'deviceId', null));
                dispatch(untouch(REDUX_FORM_NAME, 'deviceId'));
              }}
              {...rest}
            >
              <AutocompleteInput
                optionText={patient => `${patient.lastname.toUpperCase()} ${patient.firstname
                  .charAt(0)
                  .toUpperCase() + patient.firstname.slice(1)} ${
                  patient.birthdate
                    ? ` ${new Moment(patient.birthdate).format('L')}`
                    : ''
                }`
            }
                inputValueMatcher={() => null}
              />
            </ReferenceInput>
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => {
            // const { patientId, type } = formData;
            const { patientId } = formData;
            if (!patientId) {
              return null;
            }
            return (
              <ReferenceInput
              // Important sinon les choix du select ne se mettent pas à jour si une valeur était déjà sélectionnée
                key={patientId}
                label="Appareil"
                reference="Device"
                source="deviceId"
                // validate={type !== 'prise_de_mesure' && required()} TODO reactivate it later
                filter={{ patientId }}
              >
                <SelectInput
                  optionText={device => `${device.type} ${Moment(device.createdAt).format('MM/YYYY')}`}
                />
              </ReferenceInput>
            );
          }}
        </FormDataConsumer>
        <BooleanInput label="Ne pas envoyer de SMS au Patient" source="noSms" />
        <LongTextInput label="Commentaire" source="comment" />
      </SimpleForm>
    </Create>
  );
};

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

const MeetingShowActions = ({ basePath, data, resource }) => (
  <CardActions style={cardActionStyle}>
    <EditButton basePath={basePath} record={data} />
    <AbortMeetingButton basePath={basePath} record={data} />
  </CardActions>
);

export const MeetingShow = props => (
  <ShowController {...props}>
    {controllerProps => (
      <ShowView actions={<MeetingShowActions />} {...props} title="Rendez-vous" {...controllerProps}>
        <SimpleShowLayout>
          <TextField label="Lieu" source="meetingList.place.name" />
          {controllerProps.record && !controllerProps.record.meetingList && <BooleanField label="Rdv à l'extérieur ?" source="isOutside" />}
          <ScheduleField source="startAt" />
          <ReferenceField
            label="Patient"
            record={{
              firstname: 'test',
            }}
            source="patient.id"
            reference="Patient"
            linkType="show"
          >
            <FullNameField source="firstname" />
          </ReferenceField>
          <ReferenceField
            label="Appareil"
            source="device.id"
            reference="Device"
            linkType="show"
            allowEmpty
          >
            <TextField
              source="type"
            />
          </ReferenceField>
          <TextField label="Téléphone patient" source="patient.phone" />
          <FunctionField label="Type" render={record => getName(MeetingTypes, record.type)} />
          <FunctionField label="Statut" render={record => getName(MeetingStatus, record.status)} />
          <TextField label="Annotations" source="comment" />
        </SimpleShowLayout>
      </ShowView>
    )}
  </ShowController>
);

const ScheduleField = ({ source, record = {} }) => (
  <span>
    {`${new Moment(record.startAt).format('H[h]mm')} - ${new Moment(
      record.finishAt,
    ).format('H[h]mm')}`}

  </span>
);
ScheduleField.defaultProps = {
  label: 'Horaires',
  addLabel: true,
};
ScheduleField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

const FullNameField = ({ source, record = {} }) => {
  const patient = record && record.patient ? record.patient : record;

  if (!patient || !patient.firstname) {
    return <div />;
  }
  return <span>{`${patient.lastname} ${patient.firstname}`}</span>;
};
FullNameField.defaultProps = {
  label: 'Patient',
  addLabel: true,
};
FullNameField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export const MeetingEdit = ({
  meetingList, startDate, finishDate, ...props
}) => (
  <Edit {...props} title="Édition rendez-vous">
    <SimpleForm redirect="show">
      {!meetingList && (
      <DateTwoTimeInput
        startDate={startDate}
        finishDate={finishDate}
      />
      )}
      <FormDataConsumer>
        {({ formData }) => {
          const { patient: { id: patientId } } = formData;
          return (
            <ReferenceInput
              label="Appareil"
              reference="Device"
              source="device.id"
              filter={{ patientId }}
            >
              <SelectInput
                optionText={device => `${device.type} ${Moment(device.createdAt).format('MM/YYYY')}`}
              />
            </ReferenceInput>
          );
        }}
      </FormDataConsumer>
      <SelectInput
        label="Type"
        source="type"
        choices={MeetingTypes}
        validate={required()}
      />
      <SelectInput
        label="Statut"
        source="status"
        choices={MeetingStatus}
      />
      <BooleanInput label="Ne pas envoyer de SMS au Patient" source="noSms" />
      <LongTextInput source="comment" />
    </SimpleForm>
  </Edit>
);

const MeetingFilter = props => (
  <Filter {...props}>
    <SelectInput
      label="Statut"
      source="status"
      alwaysOn
      choices={MeetingStatus}
    />
    <ReferenceInput
      label="Opérateur"
      reference="User"
      source="operatorId"
      filter={{ type: 'Operator', activated: false }}
      allowEmpty
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Lieu"
      reference="MeetingPlace"
      source="meetingPlaceId"
      allowEmpty
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <SelectInput
      label="Type"
      source="type"
      choices={MeetingTypes}
    />
  </Filter>
);

const Operatorsfield = ({ record, ...rest }) => {
  const operators = [];
  if (record.operator) {
    operators.push(record.operator);
  }
  if (record.meetingList && record.meetingList.operators) {
    operators.push(...record.meetingList.operators);
  }

  return (
    <div>
      {operators.map(({ name }) => (
        <Chip label={name} />
      ))}
    </div>

  );
};

export const MeetingList = props => (
  <List {...props} title="Liste des consultations" bulkActionButtons={false} filters={<MeetingFilter />} filterDefaultValues={{ status: 'NotSeen' }}>
    <Datagrid rowClick="show">
      <ReferenceField label="Patient" source="patient.id" reference="Patient" linkType="show" sortable={false}>
        <FunctionField render={record => `${record.lastname} ${record.firstname}`} />
      </ReferenceField>
      <TextField label="Lieu" source="meetingList.place.name" sortable={false} />
      <FunctionField label="Type" render={record => getName(MeetingTypes, record.type)} />
      <Operatorsfield label="Opérateurs" />
      <DateField label="Date" source="startAt" />
    </Datagrid>
  </List>
);
